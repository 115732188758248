import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";
import { InputProps } from "@material-ui/core";
import { DateTime } from 'luxon';

import { documentImage, byezzyLogoImg, objectsImage, pdfImage, errorImage } from './assets';
import { getStorageData } from "../../../framework/src/Utilities";
export const documentImg = documentImage;
export const logoImg = byezzyLogoImg;
export const objectsImg = objectsImage;
export const pdfImg = pdfImage;
export const errorImg = errorImage;
export const baseURL = '';

interface CustomFile extends File {
  isUploading?: boolean;
  uploadProgress?: number;
  fileId?: string;
}

interface DocumentsData {
  id: string;
  type: string;
  attributes: {
    document_type: string;
    document_name: string;
    vat_reason: string;
    account_no: string;
    iban: string;
    bank_address: string;
    name: string;
    bank_name: string;
    swift_code: string;
    approved: boolean | null;
    rejected: boolean | null;
    reason_for_rejection: string;
    account_id: number;
    document_files: string[];
    created_at: string;
  }
}

export interface FilesUploaded {
  [key: string]: CustomFile
}

interface IDocumentsData {
  document_type: string;
  document_name: string;
  document_files: FilesUploaded;
  iban?: string;
  bank_address?: string;
  account_no?: string;
  name?: string;
  bank_name?: string;
  swift_code?: string;
  vat_reason?: string;
  fileIndex?: string;
}

interface ModalFiles {
  tradingLicenseFiles: keyof S;
  passportFiles: keyof S;
  residenceVisaFiles: keyof S;
  vatCertificateFiles: keyof S;
  ibanCertificateFiles: keyof S;
}

interface IFileType {
  'Trading License or Commercial Registration': () => void;
  'Passport or Residence ID': () => void;
  'Residence visa for non-nationals': () => void;
  'VAT Certificate or Reason for not having it': () => void;
  'IBAN Certificate or Bank Details': () => void;
}

interface SuccessResponse {
  data: DocumentsData[]
}

interface DocumentsRes {
  data: {
    id: string,
    attributes: {
      document_type: string
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filesUploaded: FilesUploaded;
  tradingLicenseFiles: FilesUploaded
  passportFiles: FilesUploaded
  residenceVisaFiles: FilesUploaded
  vatCertificateFiles: FilesUploaded
  ibanCertificateFiles: FilesUploaded
  tradingLicenseModal: boolean;
  passportModal: boolean;
  residenceVisaModal: boolean;
  vatCertificateModal: boolean;
  ibanCertificateModal: boolean;
  uploadedFilesIndex: {
    tradingLicense: string;
    passport: string;
    residenceVisa: string;
    vatCertificate: string;
    ibanCertificate: string;
  }
  hasVatCheckbox: boolean;
  hasNoVatCheckbox: boolean;
  vatReason: string;
  hasIbanCheckbox: boolean;
  hasNoIbanCheckbox: boolean;
  account_no: string;
  iban: string;
  bank_address: string;
  name: string;
  bank_name: string;
  swift_code: string;
  sellerId: string;
  token: string;
  disableButtonOnUpload: boolean;
  errorMessages: string[];
  sellerDocuments: DocumentsData[],
  submitting: boolean;
  accountErr: string,
  nameErr: string,
  ibanErr: string,
  bankNameErr: string,
  bankAdd: string,
  swiftCodeErr: string,
  ibanErrorMsg: string,
  VatReasonErrorMsg: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiUploadDocumentsCallId: string = "";
  apiGetSellerCallId: string = "";
  inputRef: React.RefObject<InputProps & { click: Function }>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      filesUploaded: {},
      tradingLicenseFiles: {},
      passportFiles: {},
      residenceVisaFiles: {},
      vatCertificateFiles: {},
      ibanCertificateFiles: {},
      tradingLicenseModal: true,
      passportModal: false,
      residenceVisaModal: false,
      vatCertificateModal: false,
      ibanCertificateModal: false,
      uploadedFilesIndex: {
        tradingLicense: '',
        passport: '',
        residenceVisa: '',
        vatCertificate: '',
        ibanCertificate: '',
      },
      hasVatCheckbox: true,
      hasNoVatCheckbox: false,
      vatReason: '',
      hasIbanCheckbox: true,
      hasNoIbanCheckbox: false,
      account_no: '',
      iban: '',
      bank_address: '',
      name: '',
      bank_name: '',
      swift_code: '',
      sellerId: '',
      token: '',
      disableButtonOnUpload: true,
      errorMessages: [],
      sellerDocuments: [],
      submitting: false,
      accountErr: '',
      nameErr: '',
      ibanErr: '',
      bankNameErr: '',
      bankAdd: '',
      swiftCodeErr: '',
      ibanErrorMsg: '',
      VatReasonErrorMsg: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.inputRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || {};
      if (apiRequestCallId === this.apiUploadDocumentsCallId) {
        this.handleUploadDocumentsResponseAction(responseJson);
      }
      if (apiRequestCallId === this.apiGetSellerCallId) {
        this.handleGetSellerDocumentsResponseAction(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetSellerDocumentsResponseAction = (responseJson: SuccessResponse) => {
    const responseData = responseJson;
    if ('data' in responseData) {
      this.setState({ disableButtonOnUpload: false, sellerDocuments: responseJson.data })
      const fileType = {
        'Trading License or Commercial Registration': (index: string) => this.setState({ uploadedFilesIndex: { ...this.state.uploadedFilesIndex, tradingLicense: index } }),
        'Passport or Residence ID': (index: string) => this.setState({ uploadedFilesIndex: { ...this.state.uploadedFilesIndex, passport: index } }),
        'Residence visa for non-nationals': (index: string) => this.setState({ uploadedFilesIndex: { ...this.state.uploadedFilesIndex, residenceVisa: index } }),
        'VAT Certificate or Reason for not having it': (index: string) => this.setState({ uploadedFilesIndex: { ...this.state.uploadedFilesIndex, vatCertificate: index } }),
        'IBAN Certificate or Bank Details': (index: string) => this.setState({ uploadedFilesIndex: { ...this.state.uploadedFilesIndex, ibanCertificate: index } }),
      }
      responseJson.data.forEach((file: {id: string, attributes: {document_type: string}}) => {
        fileType[file.attributes.document_type as keyof IFileType](file.id);
      })
    }
  }

  handleUploadDocumentsResponseAction = (responseJson: DocumentsRes) => {
    try {
      if (responseJson.data.attributes.document_type === 'Trading License or Commercial Registration') {
        this.setState({
          uploadedFilesIndex: { ...this.state.uploadedFilesIndex, tradingLicense: responseJson.data.id },
          tradingLicenseModal: false,
          passportModal: true,
        })
      }
      if (responseJson.data.attributes.document_type === 'Passport or Residence ID') {
        this.setState({
          uploadedFilesIndex: { ...this.state.uploadedFilesIndex, passport: responseJson.data.id },
          passportModal: false,
          residenceVisaModal: true,
        })
      }
      if (responseJson.data.attributes.document_type === 'Residence visa for non-nationals') {
        this.setState({
          uploadedFilesIndex: { ...this.state.uploadedFilesIndex, residenceVisa: responseJson.data.id },
          residenceVisaModal: false,
          vatCertificateModal: true,
        })
      }
      if (responseJson.data.attributes.document_type === 'VAT Certificate or Reason for not having it') {
        this.setState({
          uploadedFilesIndex: { ...this.state.uploadedFilesIndex, vatCertificate: responseJson.data.id },
          vatCertificateModal: false,
          ibanCertificateModal: true,
        })
      }
      if (responseJson.data.attributes.document_type === 'IBAN Certificate or Bank Details') {
        this.handleGoToSellerFinalUploadRegistration()
      }
      this.setState({ disableButtonOnUpload: false, submitting: false })
    } catch (error) {
      this.setState({
        errorMessages: ['Failed to upload. The document is corrupted, or the file size exceeds the limit (max 10 MB). Please upload a PDF document.'],
        disableButtonOnUpload: false,
        submitting: false
      })
    }
  }

  checkForIbanFormError = () => {
    return !( 
      this.state.account_no &&
      this.state.name &&
      this.state.iban &&
      this.state.bank_name &&
      this.state.bank_address &&
      this.state.swift_code
    ) || 
    !!(this.state.accountErr ||
      this.state.nameErr ||
      this.state.ibanErr ||
      this.state.bankNameErr ||
      this.state.bankAdd ||
      this.state.swiftCodeErr)
  }

  checkForDisableButtonVatIban = () => {  
    if (this.state.vatCertificateModal) {
      const hasVatFiles = Object.keys(this.state.vatCertificateFiles).length > 0;
      return (this.state.hasVatCheckbox && !hasVatFiles) || (this.state.hasNoVatCheckbox && !this.state.vatReason);
    } 
    
    if (this.state.ibanCertificateModal) {
      const noIbanFiles = Object.keys(this.state.ibanCertificateFiles).length < 1;
      return (this.state.hasIbanCheckbox && noIbanFiles) || (this.state.hasNoIbanCheckbox && this.checkForIbanFormError());
    }
    
    return false;
  }
  

  handleFileUpload = (modalType: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: CustomFile = (target.files as FileList)[0];

    if (!file) return;

    if (file && file.type !== 'application/pdf') {
      this.setState({ errorMessages: ['Failed to upload. The document is corrupted, or the file size exceeds the limit (max 10 MB). Please upload a PDF document.'], disableButtonOnUpload: false })
      return;
    }

    this.setState({ errorMessages: [], disableButtonOnUpload: true })

    file.isUploading = true;
    file.uploadProgress = 0;
    const fileId = Date.now().toString();
    file.fileId = fileId;
    let documentsToUpdate: keyof S = 'tradingLicenseFiles';

    switch (modalType) {
      case 'passport':
        documentsToUpdate = 'passportFiles';
        break;
      case 'residenceVisa':
        documentsToUpdate = 'residenceVisaFiles';
        break;
      case 'vatCertificate':
        documentsToUpdate = 'vatCertificateFiles';
        break;
      case 'ibanCertificate':
        documentsToUpdate = 'ibanCertificateFiles';
        break;
      default:
        break;
    }

    const fileInfo = Object.values(this.state[documentsToUpdate]);
    const sizeOfFile: any[] = fileInfo?.map((item: any) => item?.size) || [];
    const totalSizeOfFile  = sizeOfFile.length && sizeOfFile?.reduce((a, b) => a + b);

    const maxLimit = 10 * 1024 * 1024;
    const availableLimit = maxLimit - totalSizeOfFile;
    if (file.size > 10 * 1024 * 1024 || file.size > availableLimit) {
      this.setState({ errorMessages: ['Failed to upload. The document is corrupted, or the file size exceeds the limit (max 10 MB). Please upload a PDF document.'], disableButtonOnUpload: false })
      return;
    }

    if (fileInfo.length >= 5) {
      this.setState({ errorMessages: ['Failed to upload. Can not upload more than 5 documents.'], disableButtonOnUpload: false });
      return;
    }

    this.setState({ [documentsToUpdate]: { ...this.state[documentsToUpdate as keyof ModalFiles], [fileId]: file } } as unknown as Pick<S, keyof S>);

    const interval = setInterval(() => {
      if (this.state[documentsToUpdate as keyof ModalFiles][fileId] === undefined) {
        clearInterval(interval)
        this.setState({ disableButtonOnUpload: false })
        return;
      }
      const uploadProgress = this.state[documentsToUpdate as keyof ModalFiles][fileId].uploadProgress as keyof FilesUploaded
      file.uploadProgress = Number(uploadProgress) >= 100
        ? 100
        : Number(uploadProgress) + 10;
      this.setState({ [documentsToUpdate]: { ...this.state[documentsToUpdate as keyof ModalFiles], [fileId]: file } } as unknown as Pick<S, keyof S>);
    }, 200);

    setTimeout(() => {
      clearInterval(interval);
      file.isUploading = false;
      file.uploadProgress = 0;
      if (this.state[documentsToUpdate as keyof ModalFiles][fileId] !== undefined) {
        this.setState({
          disableButtonOnUpload: false,
          [documentsToUpdate]: { ...this.state[documentsToUpdate as keyof ModalFiles], [fileId]: file }
        } as unknown as Pick<S, keyof S>);
      }
    }, 2000);
  }

  handleRemoveFile = (modalType: string, index: string) => {
    let documentsToUpdate: keyof S = 'tradingLicenseFiles';
    switch (modalType) {
      case 'passport':
        documentsToUpdate = 'passportFiles';
        break;
      case 'residenceVisa':
        documentsToUpdate = 'residenceVisaFiles';
        break;
      case 'vatCertificate':
        documentsToUpdate = 'vatCertificateFiles';
        break;
      case 'ibanCertificate':
        documentsToUpdate = 'ibanCertificateFiles';
      default:
        break;
    }
    const newFiles = this.state[documentsToUpdate];
    delete newFiles[index];
    this.setState({ [documentsToUpdate]: newFiles } as unknown as Pick<S, keyof S>);
  }

  handleInputOnChange = (inputTarget: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    let inputReferenceToChange: keyof S = 'vatReason';
    inputReferenceToChange = inputTarget as keyof S;
    this.setState({ [inputReferenceToChange]: value } as unknown as Pick<S, keyof S>);
    this.setState({ VatReasonErrorMsg: ""})
    switch (inputTarget) {
      case 'account_no' : this.handleAccountNumber(value)
      break;
      case 'name': this.handleName(value)
      break;
      case 'iban' : this.handleIBan(value)
      break;
      case 'bank_name' : this.handleBankName(value)
      break;
      case 'bank_address' : this.handleBankAddress(value)
      break;
      case 'swift_code': this.handleSwiftCode(value)
      break;
      case 'vatReason': this.handleVatCertificateInput(value)
      break;
      default: break;
    }
  }

  handleVatCertificateInput = (value: string) => {
    const isValidVat = /^(?! )[a-zA-Z0-9][a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*(?<! )$/.test(value);
    if (!isValidVat) {
      this.setState({ VatReasonErrorMsg: 'Input should not contains any special charaters and cannot have leading or trailing spaces.', disableButtonOnUpload: true });
    }
    else this.setState({ VatReasonErrorMsg: '', disableButtonOnUpload: false })
  }

  handleAccountNumber = (value: string) => {
    const isValidAccountNo = /^\d{11,20}$/.test(value);
    if (!isValidAccountNo) {
        this.setState({accountErr: 'Account number must be between 11 and 20 digits.'});
    }
    else this.setState({accountErr: ''});
  }

  handleName = (value: string) => {
    const isValidName = /^(?! )[a-zA-Z ]{3,50}(?<! )$/.test(value);
    if (!isValidName) {
        this.setState({nameErr: 'Input must be between 3 and 50 characters long and can only contain letters and spaces.'});
    }
    else this.setState({nameErr: ''});
  }

  handleIBan = (value:string) => {
    const isValidIban = /^AE\d{22}$/.test(value);
    if (!isValidIban) {
        this.setState({ibanErr: 'Input should be in IBAN format (e.g AE1234567891234567890123)'});
    }
    else this.setState({ibanErr: ''});
  }

  handleBankName = (value: string) => {
    const isValidBankName = /^(?! )[a-zA-Z ]{3,50}(?<! )$/.test(value);
    if (!isValidBankName) {
        this.setState({bankNameErr: 'Input must be between 3 and 50 characters long and can only contain letters and spaces.'});
    }
    else this.setState({bankNameErr: ''});
  }

  handleBankAddress = (value: string) => {
    const isValidBankAddress = /^(?! )[a-zA-Z0-9 ,\-\/]{20,100}(?<! )$/.test(value);
    if (!isValidBankAddress) {
        this.setState({bankAdd: 'Input must be between 20 and 100 characters long and can only contain letters and spaces.'});
    }
    else this.setState({bankAdd: ''});
  }

  handleSwiftCode = (value: string) => {
    const isValidCode = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,11}$/.test(value);
    if (!isValidCode) {
        this.setState({swiftCodeErr: 'Should be Alphanumeric between 8 to 11 characters'});
    }
    else this.setState({swiftCodeErr: ''});
  }

  handleOpenFileDialog = () => {
    this.inputRef.current?.click();
  }

  handleOnSubmitAction = (modalType: string) => {
    this.setState({ errorMessages: [], VatReasonErrorMsg: '' })
    switch (modalType) {
      case 'tradingLicense':
        this.uploadSellerDocuments({
          document_type: 'Trading License or Commercial Registration',
          document_name: 'Trading License or Commercial Registration',
          document_files: this.state.tradingLicenseFiles,
          ...(this.state.uploadedFilesIndex.tradingLicense ? { fileIndex: this.state.uploadedFilesIndex.tradingLicense } : {})
        })
        break;
      case 'passport':
        this.uploadSellerDocuments({
          document_type: 'Passport or Residence ID',
          document_name: 'Passport or Residence ID(both sided)',
          document_files: this.state.passportFiles,
          ...(this.state.uploadedFilesIndex.passport ? { fileIndex: this.state.uploadedFilesIndex.passport } : {})
        })
        break;
      case 'residenceVisa':
        this.uploadSellerDocuments({
          document_type: 'Residence visa for non-nationals',
          document_name: 'Residence visa for non-nationals',
          document_files: this.state.residenceVisaFiles,
          ...(this.state.uploadedFilesIndex.residenceVisa ? { fileIndex: this.state.uploadedFilesIndex.residenceVisa } : {})
        })
        break;
      case 'vatCertificate':
        const vatDocumentsData = {
          document_type: 'VAT Certificate or Reason for not having it',
          ...(this.state.uploadedFilesIndex.vatCertificate ? { fileIndex: this.state.uploadedFilesIndex.vatCertificate } : {})
        } as IDocumentsData;
        if (this.state.hasVatCheckbox) {
          if (Object.keys(this.state.vatCertificateFiles).length <= 0) {
            this.setState({ errorMessages: ['Missing file! Please attach a valid file!'] })
            break;
          }
          vatDocumentsData.document_name = 'VAT Certificate';
          vatDocumentsData.document_files = this.state.vatCertificateFiles;
          this.uploadSellerDocuments(vatDocumentsData);
        }
        if (this.state.hasNoVatCheckbox) {
          if(!this.state.vatReason.trim()){
            this.setState({ VatReasonErrorMsg: "Reason field can't be blank." })
            break;
          } else {
            vatDocumentsData.document_name = "Don't have VAT Certificate";
            vatDocumentsData.vat_reason = this.state.vatReason;
            this.uploadSellerDocuments(vatDocumentsData);
          }
        } 
        break;
      case 'ibanCertificate':
        const ibanDocumentsData = {
          document_type: 'IBAN Certificate or Bank Details',
          ...(this.state.uploadedFilesIndex.ibanCertificate ? { fileIndex: this.state.uploadedFilesIndex.ibanCertificate } : {})
        } as IDocumentsData;
        if (this.state.hasIbanCheckbox) {
          if (Object.keys(this.state.ibanCertificateFiles).length <= 0) {
            this.setState({ errorMessages: ['Missing file! Please attach a valid file!'] })
            break;
          }
          ibanDocumentsData.document_name = 'IBAN Certificate';
          ibanDocumentsData.document_files = this.state.ibanCertificateFiles;
          this.uploadSellerDocuments(ibanDocumentsData)
        }
        if (this.state.hasNoIbanCheckbox &&
          this.state.bank_address &&
          this.state.bank_name &&
          this.state.swift_code &&
          this.state.iban &&
          this.state.name &&
          this.state.account_no
        ) {
          ibanDocumentsData.document_name = "Don't have IBAN Certificate";
          ibanDocumentsData.account_no = this.state.account_no;
          ibanDocumentsData.iban = this.state.iban;
          ibanDocumentsData.name = this.state.name;
          ibanDocumentsData.swift_code = this.state.swift_code;
          ibanDocumentsData.bank_address = this.state.bank_address;
          ibanDocumentsData.bank_name = this.state.bank_name;
          const {accountErr, nameErr, bankNameErr, bankAdd, swiftCodeErr, ibanErr} = this.state
          if(!accountErr && !nameErr && !bankNameErr && !bankAdd && !swiftCodeErr && !ibanErr) {
            this.setState({ibanErrorMsg: ""})
            this.uploadSellerDocuments(ibanDocumentsData)
          }
          else this.setState({ibanErrorMsg: "Fill all required fields"})
        } else {
          this.setState({ibanErrorMsg: "Fill all required fields"})
        }
        if(this.state.hasIbanCheckbox) {
          this.setState({ibanErrorMsg: ""});
        };
        break;
      default:
        break;
    }

  }

  handleOnBackButton = (modalType: string) => {
    switch (modalType) {
      case 'tradingLicense':
        this.handleGoToSellerLandingPage()
        break;
      case 'passport':
        this.setState({
          tradingLicenseModal: true,
          passportModal: false,
          residenceVisaModal: false,
          vatCertificateModal: false,
          ibanCertificateModal: false,
        })
        break;
      case 'residenceVisa':
        this.setState({
          tradingLicenseModal: false,
          passportModal: true,
          residenceVisaModal: false,
          vatCertificateModal: false,
          ibanCertificateModal: false,
        })
        break;
      case 'vatCertificate':
        this.setState({
          tradingLicenseModal: false,
          passportModal: false,
          residenceVisaModal: true,
          vatCertificateModal: false,
          ibanCertificateModal: false,
        })
        break;
      case 'ibanCertificate':
        this.setState({
          tradingLicenseModal: false,
          passportModal: false,
          residenceVisaModal: false,
          vatCertificateModal: true,
          ibanCertificateModal: false,
        })
        break;
      default:
      //
    }
  }

  uploadSellerDocuments = (documentsData: IDocumentsData) => {
    this.setState({ disableButtonOnUpload: true, submitting: true })

    let method = configJSON.postMethod;
    let endpoint = configJSON.apiUploadDocumentEndpoint + this.state.sellerId + '/seller_documents';

    if (documentsData.fileIndex) {
      method = configJSON.patchMethod;
      endpoint = configJSON.apiUploadDocumentEndpoint + this.state.sellerId + '/seller_documents/' + documentsData.fileIndex;
    }

    const headers = {
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.apiUploadDocumentsCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )

    const formData = new FormData();
    documentsData.document_files && Object.keys(documentsData.document_files).map(keyValue => documentsData.document_files[keyValue]).map((file) => {
      formData.append("document_files[]", file);
    })
    documentsData.document_type && formData.append('document_type', documentsData.document_type)
    documentsData.document_name && formData.append('document_name', documentsData.document_name)
    documentsData.vat_reason && formData.append('vat_reason', documentsData.vat_reason)
    documentsData.account_no && formData.append('account_no', documentsData.account_no)
    documentsData.iban && formData.append('iban', documentsData.iban)
    documentsData.bank_address && formData.append('bank_address', documentsData.bank_address)
    documentsData.name && formData.append('name', documentsData.name)
    documentsData.bank_name && formData.append('bank_name', documentsData.bank_name)
    documentsData.swift_code && formData.append('swift_code', documentsData.swift_code)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  checkSellerDocuments = () => {
    const headers = {
      token: this.state.token
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    )
    this.apiGetSellerCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiUploadDocumentEndpoint + this.state.sellerId + '/seller_documents'
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers),
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod,
    )
    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  handleHasVatOnChange = () => {
    this.setState({ hasVatCheckbox: true, hasNoVatCheckbox: false })
  }

  handleHasNoVatOnChange = () => {
    this.setState({ hasNoVatCheckbox: true, hasVatCheckbox: false })
  }

  handleHasIbanOnChange = () => {
    this.setState({ hasIbanCheckbox: true, hasNoIbanCheckbox: false })
  }

  handleHasNoIbanOnChange = () => {
    this.setState({ hasNoIbanCheckbox: true, hasIbanCheckbox: false })
  }

  handleGoToSellerFinalUploadRegistration = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationSellerFinalUploadRegistrationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }


  getToken = async () => {
    const token = await getStorageData("authToken");
    const sellerId = await getStorageData("seller_id");
    this.setState({ token, sellerId });
  }

  handleGoToSellerLandingPage = () => {
    this.docVerificationNotification();
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SellerLanding");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  docVerificationNotification = () => {
    const headers = { token: this.state.token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.docVerification);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleConvertDateToReadable = (date: string) => {
    const luxonDateTime = DateTime.fromISO(date).setZone("local");
    return luxonDateTime.toFormat("LLL dd, yyyy | hh:mm a");
  }

  async componentDidMount(): Promise<void> {
    await this.getToken();
    this.checkSellerDocuments();
  }
  // Customizable Area End
}
